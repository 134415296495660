import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({ children }) => {
	const { isAuth } = useSelector(({ auth }) => auth, shallowEqual);
	const location = useLocation();

	return isAuth === true ? (
		children
	) : (
		<Navigate to='/login' replace state={{ path: location.pathname }} />
	);
};

export default RequireAuth;
